@charset "UTF-8";
/*------------------------------------*\
  # Path
\*------------------------------------*/
/*------------------------------------*\
    # Colors
    # http://chir.ag/projects/name-that-color
\*------------------------------------*/
/*------------------------------------*\
    # Basic typography
\*------------------------------------*/
/*------------------------------------*\
    # Wrapper
\*------------------------------------*/
/*------------------------------------*\
  # Print
\*------------------------------------*/
/*------------------------------------*\
  # Magnific-Popup settings
\*------------------------------------*/
/**
 * Преобразование пикселей в rem на основе переменной с базовым размером шрифта.
 * @param  {number} $px Число пикселей (с единицами измерения или без)
 * @param  {string} Число пикселей, размер контентного шрифта на проекте
 * @return {string} Результат преобразования $px в rem
 */
/*------------------------------------------------------------------------*\
  # Hides an element visually while still allowing the content to be accessible,
    to assistive technology, e.g. screen readers. Passing `unhide` will reverse
    the affects of the hiding, which is handy for showing the element on focus,
    for example.
  # https://goo.gl/Vf1TGn
\*------------------------------------------------------------------------*/
/*------------------------------------*\
  # Transitions
\*------------------------------------*/
/*------------------------------------*\
  # Placeholder
\*------------------------------------*/
.catalog-questions {
  width: 100%;
  margin-bottom: 40px; }
  .catalog-questions-bg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 40px 10%; }
    @media (max-width: 1400px) {
      .catalog-questions-bg {
        padding-right: 20px; } }
    @media (max-width: 1150px) {
      .catalog-questions-bg {
        padding-left: 20px; } }
    @media (max-width: 840px) {
      .catalog-questions-bg {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 15px 10px; } }
  .catalog-questions-text {
    position: relative;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    line-height: 21px;
    margin-right: 32px; }
    @media (max-width: 840px) {
      .catalog-questions-text {
        margin-right: 0; } }
    .catalog-questions-text:after {
      position: absolute;
      left: -108px;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      width: 64px;
      height: 6px;
      background-color: #ffc519; }
      @media (max-width: 1150px) {
        .catalog-questions-text:after {
          display: none; } }
    .catalog-questions-text h2 {
      font-size: 2.75rem;
      color: #ffff;
      line-height: 2.75rem; }
      @media (max-width: 640px) {
        .catalog-questions-text h2 {
          font-size: 2rem;
          line-height: 2rem; } }
    .catalog-questions-text p {
      font-size: 2rem;
      color: #c0fff6;
      line-height: 2rem; }
      @media (max-width: 640px) {
        .catalog-questions-text p {
          font-size: 1.5rem;
          line-height: 1.5rem; } }
  .catalog-questions-btn a {
    width: 260px;
    height: 50px;
    border-radius: 8px;
    background-color: #ffbf00;
    display: block;
    text-align: center;
    transition: all 0.2s ease-in-out 0s; }
    @media (max-width: 1150px) {
      .catalog-questions-btn a {
        width: auto;
        padding: 0 20px;
        height: 40px; } }
    @media (max-width: 840px) {
      .catalog-questions-btn a {
        margin-top: 10px; } }
    .catalog-questions-btn a span {
      color: #ffffff;
      font-family: "Montserrat", sans-serif;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 50px;
      text-transform: uppercase; }
      @media (max-width: 1150px) {
        .catalog-questions-btn a span {
          line-height: 40px; } }
    .catalog-questions-btn a:hover {
      text-decoration: none;
      background-color: #f0b400; }

.error-page .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 188px;
  padding-bottom: 188px; }
  @media (max-width: 1400px) {
    .error-page .wrapper {
      padding-top: 160px;
      padding-bottom: 160px; } }
  @media (max-width: 1200px) {
    .error-page .wrapper {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media (max-width: 800px) {
    .error-page .wrapper {
      padding-top: 40px;
      padding-bottom: 40px; } }
  @media (max-width: 480px) {
    .error-page .wrapper {
      padding-top: 24px;
      padding-bottom: 24px; } }
  .error-page .wrapper h1 {
    font-size: 24px;
    font-weight: 800;
    color: #292929;
    text-transform: uppercase;
    text-align: center;
    margin-top: 24px;
    letter-spacing: 1px; }
    @media (max-width: 800px) {
      .error-page .wrapper h1 {
        font-size: 18px;
        margin-top: 35px; } }
    @media (max-width: 480px) {
      .error-page .wrapper h1 {
        margin-top: 15px; } }
  .error-page .wrapper div img {
    display: block;
    width: 100%; }
  @media (max-width: 800px) {
    .error-page .wrapper div {
      margin-top: 35px; } }
  @media (max-width: 480px) {
    .error-page .wrapper div {
      margin-top: 15px; } }
  .error-page .wrapper a {
    margin-top: 55px;
    width: 100%;
    max-width: 325px;
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    color: #fff;
    background-color: #1C4A9F;
    height: 50px;
    line-height: 50px;
    border-radius: 8px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.5px; }
    @media (max-width: 800px) {
      .error-page .wrapper a {
        margin-top: 35px; } }
    @media (max-width: 480px) {
      .error-page .wrapper a {
        margin-top: 15px; } }
    .error-page .wrapper a:hover {
      background-color: #25e8cb; }
    .error-page .wrapper a:active {
      background-color: #17ddbf; }
