/*------------------------------------------------------------------------*\
  # Hides an element visually while still allowing the content to be accessible,
    to assistive technology, e.g. screen readers. Passing `unhide` will reverse
    the affects of the hiding, which is handy for showing the element on focus,
    for example.
  # https://goo.gl/Vf1TGn
\*------------------------------------------------------------------------*/
@mixin hide-visually($toggle: "hide") {
  @if not index("hide" "unhide", $toggle) {
    @error "`#{$toggle}` is not a valid value for the `$toggle` argument in " +
           "the `hide-visually` mixin. Must be either `hide` or `unhide`.";
  } @else if $toggle == "hide" {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  } @else if $toggle == "unhide" {
    clip: auto;
    clip-path: none;
    height: auto;
    overflow: visible;
    position: static;
    white-space: inherit;
    width: auto;
  }
}

/*------------------------------------*\
  # Transitions
\*------------------------------------*/
@mixin transition($properties...) {
  @if length($properties) >= 1 {
    transition: $properties;
  } @else {
    transition: all 0.2s ease-in-out 0s;
  }
}

/*------------------------------------*\
  # Placeholder
\*------------------------------------*/
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin scrollhide() {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin scrollbar($bg: false, $color: false) {
  @if $bg and $color {
    scrollbar-width: thin;
    scrollbar-color: $color $bg;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
      background-color: $bg;

      &-thumb {
        background-color: $color;
      }
    }
  } @else {
    scrollbar-width: thin;
    scrollbar-color: #4f4f52 #eeeeee;

    &::-webkit-scrollbar {
      height: 2px;
      width: 2px;
      background-color: #eeeeee;

      &-thumb {
        background-color: #4f4f52;
      }
    }
  }
}
