.error-page{
  .wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 188px;
    padding-bottom: 188px;
    @media (max-width: 1400px){
      padding-top: 160px;
      padding-bottom: 160px;
    }
     @media (max-width: 1200px){
      padding-top: 80px;
      padding-bottom: 80px;
    }
    @media (max-width: 800px){
      padding-top: 40px;
      padding-bottom: 40px;
    }
    @media (max-width: 480px){
      padding-top: 24px;
      padding-bottom: 24px;
    }
    h1{
      font-size: 24px;
      font-weight: 800;
      color: #292929;
      text-transform: uppercase;
      text-align: center;
      margin-top: 24px;
      letter-spacing: 1px;
       @media (max-width: 800px){
         font-size: 18px;
         margin-top: 35px;
        }
        @media (max-width: 480px){
          margin-top: 15px;
        }
    }
    div{
      img{
        display: block;
        width: 100%;
      }
      @media (max-width: 800px){
         margin-top: 35px;
      }
      @media (max-width: 480px){
        margin-top: 15px;
      }
    }
    a{
      margin-top: 55px;
      width: 100%;
      max-width: 325px;
      font-size: 16px;
      font-weight: 800;
      text-align: center;
      color: #fff;
      background-color:$green;
      height: 50px;
      line-height: 50px;
      border-radius: 8px;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      @media (max-width: 800px){
         margin-top: 35px;
      }
      @media (max-width: 480px){
        margin-top: 15px;
      }
      &:hover{
        background-color: lighten(#17ddbf, 5%);
      }
      &:active{
        background-color: #17ddbf;
      }
    }
  }
}