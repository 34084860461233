.catalog-questions{
  width: 100%;
  margin-bottom: 40px;
  &-bg{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 40px 10%;
    @media (max-width: 1400px){
      padding-right: 20px;
    }
    @media (max-width: 1150px){
      padding-left: 20px;
    }
    @media (max-width: 840px){
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 15px 10px;
    }
  }
  &-text{
    position: relative;
    font:{
      family: $ossem;
      weight: $font-weight--regular;
    }
    line-height: 21px;
    margin-right: 32px;
     @media (max-width: 840px){
       margin-right: 0;
     }
    &:after{
      position: absolute;
      left: -108px;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      width: 64px;
      height: 6px;
      background-color: #ffc519;
      @media (max-width: 1150px){
        display: none;
      }
    }
    h2{
      font-size: rem(44px);
      color: #ffff;
      line-height: rem(44px);
      @media (max-width: 640px){
        font-size: rem(32px);
        line-height: rem(32px);
      }
    }
    p{
      font-size: rem(32px);
      color: #c0fff6;
      line-height: rem(32px);
       @media (max-width: 640px){
         font-size: rem(24px);
         line-height: rem(24px);
      }
    }
  }
  &-btn{
    a{
      width: 260px;
      height: 50px;
      border-radius: 8px;
      background-color: #ffbf00;
      display: block;
      text-align: center;
      @include transition();
      @media (max-width: 1150px){
        width: auto;
        padding: 0 20px;
        height: 40px;
      }
      @media (max-width: 840px){
        margin-top: 10px;
      }
      span{
        color: #ffffff;
        font-family: $baseFonts;
        font-size: rem(14px);
        font-weight: $font-weight--bold;
        line-height: 50px;
        text-transform: uppercase;
         @media (max-width: 1150px){
           line-height: 40px;
         }
      }
      &:hover{
        text-decoration: none;
        background-color: darken(#ffbf00,3%);
      }
    }
  }
}