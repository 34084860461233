/*------------------------------------*\
  # Path
\*------------------------------------*/
$img-path: '../images';
$svg-path: '../images/vector';

/*------------------------------------*\
    # Colors
    # http://chir.ag/projects/name-that-color
\*------------------------------------*/
$base-color: rgb(41, 41, 41);

$base-shade-1: #31302F;

$newblack: #404040;

$body-bg-color: #f8f8f8;

//$link-color: #A1E7D8;
$link-color: #1C4A9F;
$link-color--hover: lighten($link-color, 5%);
$link-color--active: darken($link-color, 10%);
//$filter-item--hover: #63ACA8;
$filter-item--hover: lighten($link-color, 5%);

$gray: #b7b7b7;
$gray-shade-1: #F7F7F7;
$gray-shade-2: #A9A5A5;
$gray-shade-3: #a2a2a2;
//$yellow: #A1E7D8;
$yellow: #1C4A9F;
//$green: #9fe6d6;
$green: #1C4A9F;
//$green-shade-1: #549692;
$green-shade-1: #1C4A9F;
//$green-shade-2: #65ADA9;
$green-shade-2: #1C4A9F;

$white: #ffffff;


/*------------------------------------*\
    # Basic typography
\*------------------------------------*/
$font-size--root: 16px;

$font-size: 1rem; // rem(16px)

$font-size--h1: rem(40px);
$font-size--h2: rem(32px);
$font-size--h3: rem(24px);
$font-size--h4: rem(18px);
$font-size--h5: rem(16px);
$font-size--h6: rem(16px);

$font-size--small: 80%;


$font-weight--extralite: 400;
$font-weight--lite: 400;
$font-weight--regular: 400;
$font-weight--semibold: 600;
$font-weight--bold: 600;
$font-weight--black: 600;

$line-height: normal;
$baseFonts: 'Montserrat', sans-serif;
$ossem: $baseFonts;

/*------------------------------------*\
    # Wrapper
\*------------------------------------*/
$wrapper-min-width: 280px;
$wrapper-max-width: 1920px;
$wrapper-padding: 0 20px;

/*------------------------------------*\
  # Print
\*------------------------------------*/
$print-page-margin: 1cm;

/*------------------------------------*\
  # Magnific-Popup settings
\*------------------------------------*/
$mfp-overlay-color: #000;
$mfp-overlay-opacity: .5;